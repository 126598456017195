@import "./header/header.styles.scss";
@import "./footer/footer.styles.scss";
@import "../../assets/styles/base/_variables.scss";

$sidebar-width: 24rem;
$sidebar-width-mobile: 100vw;

#layout {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    background-color: $bg-dark;
    position: relative;

    .sidebar-container {
        width: $sidebar-width-mobile;
        left: 0;
        z-index: 99;
        background-color: $white;
        position: absolute;
        @include transition;

        @media #{$lg-mobile-screen-only} {
            width: $sidebar-width;
        }

        &.hide {
            left: -100vw;

            @media #{$sm-desktop-screen} {
                left: -$sidebar-width;
            }
        }
    }

    .main {
        @include transition;
        width: 100%;
        height: 100%;
        margin-left: 0;

        &.--sidebar-active {
            margin-left: $sidebar-width;
            width: calc(100vw - #{$sidebar-width});
            overflow-x: hidden;
            opacity: 0;
            display: none;
            @media #{$lg-mobile-screen-only} {
                opacity: 1;
                display: block;
            }

            // this was  commented be cause it was taking the grid space.
            // #header,
            // .content {
            //     padding-left: 9.6em;
            // }
        }
        #header,
        .content {
            @include transition;
        }
    }

    .content {
        z-index: 4;
        background-color: #f9f9fa;
        width: 100%;
        border: 0;
        overflow-x: hidden;

        @media #{$tablet-screen} {
            // the screen height minus the header height
            height: calc(100vh - 56px) !important;
        }

        //padding-top: 5.2rem;

        //in bigger screen we already have 20px of padding top and botton for
        //the section component. so 3.2rem + 2rem = 5.2rem
        // @media #{$tablet-screen-only, $lg-mobile-screen-only} {
        //     padding-top: 5.2rem;
        // }

        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #eee;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #aaa;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #aaa;
        }

        &:first-child {
            border: 0 !important;
            outline: hidden !important;
            border-radius: 10px;
        }
        &.loading__component {
            background-color: #eaeaea;
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 46px); // substract mobile header height
            @media #{$lg-mobile-screen} {
                height: calc(100vh - 56px); // substract desktop header height
            }
        }
    }

    &.loading__component-layout {
        background-color: $white;
    }
    // .footer {
    //     margin-right: 20px;
    //     grid-column: 1/4;
    //     max-height: 20px;
    // }

    // &.layout-dashboard {
    //     .content {
    //         margin-left: 20px;
    //         margin-right: 20px;
    //     }
    // }
}
