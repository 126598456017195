@import "../../../../../assets/styles/global.scss";

$element-color: #f9f9fa;

#module {
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .module--menu {
        box-shadow: none;
        padding: 0;
        margin-left: -5px;
        svg {
            font-size: 2.4rem;
            margin: 0;
            path {
                transition: all 0.3s ease;
                color: $element-color;
            }
        }

        &:hover,
        &:active,
        &.active {
            path {
                color: $primary;
            }
        }
    }

    p {
        @include unSelect;
        text-transform: capitalize;
        font-size: $font-xl;
        text-align: left;
        color: $element-color;
        margin-left: 16px;
    }

    &.loading__component {
        content: "";
        width: 150px;
        height: 25px;
        display: flex;
        &::after {
            content: "";
            display: block;
            flex-grow: 1;
            background-color: $element-color;
            border-radius: 4px;
            height: 100%;
            @include componentLoader;
        }

        &::before {
            margin-right: 10px;
            content: "";
            display: block;
            width: 24px;
            height: 100%;
            background-color: $element-color;
            border-radius: 4px;
            @include componentLoader;
        }
    }
}
