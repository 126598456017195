@import "../../../../../../assets/styles/global.scss";

.employees {
    .employee_config {
        .button {
            justify-self: flex-end;
        }
    }

    .employee_line {
        display: grid;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        .name {
            color: $geyser-900;
            font-size: $font-lg;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .email {
            color: $geyser-400;
            font-size: 1.3rem;
            text-align: left;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .icon {
            font-size: 2rem;
            &.actived {
                color: $elm-500;
            }
        }
    }

    .form {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 20px;
        row-gap: 10px;

        .employee_form__line {
            display: grid;
            grid-template-columns: repeat(3, 1fr) 30px;
            align-items: center;
            column-gap: 20px;
            margin-bottom: 20px;
            .input__wrapper {
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                > label {
                    margin-bottom: 5px;
                    font-size: $font-sm;
                    color: $geyser-800;
                    font-size: 1.2rem;
                }
                > input {
                    width: 100%;
                }
            }
            .remove_employee {
                align-self: flex-end;
                svg {
                    font-size: 2rem;
                    transform: translateY(-8px);
                }
            }
        }
    }

    .error__box {
        margin-bottom: 20px;
    }
}
