@import "../../../../../assets/styles/global.scss";

.input__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    > ul {
        display: none;
        flex-direction: column;
        border: 1px solid $stroke-color;
        border-radius: 4px;
        overflow-y: auto;
        max-height: 189px;
        background-color: $white;
        @extend %dropdownHidden;
        &.show {
            display: flex;
            @extend %dropdownShow;
        }
        > li {
            width: 100%;
            font-size: $font-sm;
            color: $geyser-900;
            padding: 8px;
            text-align: center;
            cursor: pointer;
            &:hover,
            &:active {
                background-color: $light-info;
            }
        }
    }

    .input__time {
        border: none;
        padding: 8px 4px;
        position: relative;
        width: 100px;
        border-bottom: 2px solid $stroke-color;
        margin-top: 20px;
        outline: none;
        color: $geyser-700;
        font-size: $font-md;
        &::placeholder {
            color: $geyser-200;
        }
        &__border {
            border: 1px solid $stroke-color !important;
            border-radius: 4px;
        }
    }
}
