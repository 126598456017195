@import "../../../../../../assets/styles/global.scss";

.note__item {
    display: grid;
    position: relative;
    transition: all 0.1s ease-in;
    padding: 10px 12px;
    width: 100%;

    > .note__desc {
        font-size: $font-sm;
        color: $geyser-900;
        padding-bottom: 1rem;
        font-weight: 400;
        text-align: left;
        //  because of the loader absolute.
        padding-right: 20px;
    }
    > .note__date {
        justify-self: flex-start;
        font-weight: 400;
        font-size: $font-xsm;
        color: $geyser-300;
        margin-top: 5px;
        letter-spacing: $ls-5xl;
        text-transform: uppercase;
    }
    > input {
        width: 100%;
        text-align: right;
        border: none !important;
        background: rgba(255, 255, 255, 0.5) !important;
    }

    hr {
        border: none;
        border-top: 1px solid $stroke-color;
        width: 40%;
        margin-right: auto;
    }

    // only if onUpdate is set.
    &.update__event {
        cursor: pointer;
        &:hover {
            background-color: $light-secondary-3;
            > svg {
                visibility: visible;
            }

            hr {
                border-top: 1px solid $grey-200;
            }
        }
    }

    // notes without an author.
    &.from__system {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: default;
        .note__desc {
            color: $info !important;
        }

        > * {
            margin: 0px !important;
            margin-right: 5px !important;
            padding: 0px !important;
        }

        &:hover {
            background-color: transparent;

            hr {
                border-top: 1px solid $stroke-color;
            }
        }
    }

    // animation for a new note.
    &.new {
        opacity: 0.5;
        position: relative;

        .loading__new {
            position: absolute;
            top: 50%;
            transform: translateY(50%);
            right: 5px;
        }
    }
}
