@import "../../../../assets/styles/global.scss";
@page {
    size: auto;
    margin: 0;
    body {
        margin: 1.6cm;
    }
}

#header,
#nav,
.noprint {
    display: none;
}

.invoice__pdf {
    @page {
        size: auto; /* auto is the initial value */
        margin: 0; /* this affects the margin in the printer settings */
    }
    // display: flex; // Don't justify flex, we want the content full width so the invoice is print as a US Letter or whatever the size of the paper
    // justify-content: center;
    // position: absolute;
    // left: -100vw;
    // top: -100vh;
    .content__wrapper {
        // width: 612px;
        border: solid 1px $geyser-50;
        margin-top: 6rem;
        margin-bottom: 4rem;
    }
    .invoice__infos {
        background-color: $white;
        padding: 40px 40px 24px 40px;
        .row:not(:last-child) {
            margin-bottom: 32px;
        }
        .invoice__details,
        .invoice__details__right {
            > h4 {
                color: $geyser-900;
                font-weight: 400;
            }
            > p {
                font-size: $font-sm;
                color: $geyser-500;
            }
            .label {
                font-size: 1.3rem;
                color: $geyser-700;
                margin-top: 10px;
            }
        }
        .invoice__details__right {
            > h6 {
                font-weight: 400;
                text-align: right;
            }
            > p {
                text-align: right;
            }
        }
        .description__price,
        .description__price__value {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px $geyser-900;
            margin-bottom: 24px;
            > h6 {
                font-size: 1.3rem;
                color: $geyser-900;
            }
        }
        .description__price__value {
            border-bottom: none;
            > p {
                font-size: 1.3rem;
                color: $geyser-500;
            }
        }
        .note {
            > h6 {
                font-size: 1.3rem;
                color: $geyser-900;
            }
            > p {
                font-size: $font-sm;
                color: $geyser-500;
            }
        }
    }

    .payment__summary {
        background-color: $geyser-50;
        padding: 40px;
        .payment__infos {
            > h6 {
                font-size: 1.3rem;
                color: $geyser-900;
                margin-bottom: 16px;
            }
            .info {
                margin-bottom: 12px;
                > h6 {
                    font-size: 1.3rem;
                    color: $geyser-700;
                }
                > p {
                    font-size: $font-sm;
                    color: $geyser-500;
                }
            }
        }

        .total {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            > div {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                > p {
                    font-size: 1.3rem;
                    color: $geyser-900;
                    font-weight: 500;
                    text-align: right;
                }
                > span {
                    font-size: $font-sm;
                    color: $geyser-700;
                    text-align: right;
                    min-width: 70px;
                }
            }
            .balance {
                flex-direction: column;
                .line {
                    width: 60px;
                    height: 1px;
                    background-color: $geyser-900;
                    margin-top: 5px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
