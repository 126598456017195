@import "~@soltivo/draw-a-line/core/assets/styles/index";
@import "./base/mixins";

//overide resets.

body {
    line-height: 1;
    height: 100vh;
    background: $bg-body;

    #root {
        height: 100%;
    }
}

* {
    font-family: "Poppins", sans-serif !important;
}

*::selection {
    background-color: $primary;
    color: $white;
}
a {
    &:active {
        color: $primary;
    }
}

*.unselectable {
    @include unSelect;
}

.input--labeled {
    label {
        @include setFontFamily("Regular");
        @include setFontSize("md");
        color: $grey-400;
        padding-bottom: 10px;
    }
    input {
        @include setFontFamily("Regular");
        @include setFontSize("md");
        padding: 6px 10px !important;
    }
}

.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.primary-text {
    @include setFontFamily("Medium");
    @include setFontSize("lg");
    line-height: 24px;
    color: $primary;
}

.regular-text {
    @include setFontFamily("Regular");
    @include setFontSize("lg");
    line-height: 24px;
    color: $grey-500;
}

.small-text {
    @include setFontFamily("Light");
    @include setFontSize("md");
    line-height: 21px;
    color: $grey-400;
}
.private--loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

[data-title] {
    position: relative;
    &:after {
        display: grid;
        width: max-content;
        font-size: $font-sm;
        @include setTitlePosition(right, top);
        content: attr(data-title);
        border-bottom-left-radius: 0px;
        position: absolute;
        z-index: 1000;
        visibility: hidden;
        line-height: 15px;
        font-weight: 200;
        white-space: pre-line;
        padding: 8px;
        border-radius: 6px 6px 6px 0px;
        background: $bluemine-400;
        color: $white;
        box-shadow: $dp-2;
    }
    //custom title
    &:hover:after {
        transition: all 0.1s ease 0.3s;
        visibility: visible;
        display: block;
    }

    &[data-title-position="top-left"] {
        &:after {
            @include setTitlePosition(left, top);
        }
    }

    &[data-title-position="top-right"] {
        &:after {
            @include setTitlePosition(right, top);
        }
    }

    &[data-title-position="bottom-left"] {
        &:after {
            @include setTitlePosition(left, bottom);
        }
    }

    &[data-title-position="bottom-right"] {
        &:after {
            @include setTitlePosition(right, bottom);
        }
    }
}

.app-container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 32px;
    @media #{$lg-mobile-screen} {
        padding: 0 96px;
    }
    @media #{$lg-desktop-screen} {
        width: 1728px;
    }
}