@import "../../../../../assets/styles/global.scss";

.header_section_navigation {
    display: flex;
    padding: 12px 0;
    gap: 8px;

    @media #{$tablet-screen} {
        gap: 16px;
        padding: 0;
    }
    @media #{$sm-desktop-screen} {
        gap: 36px;
    }

    &__link {
        min-width: 92px;
        text-align: center;
        padding: 4px 8px;
        color: $bluemine-200;
        width: 100%;

        @media #{$tablet-screen} {
            width: initial;
        }

        &__active {
            border-radius: 24px;
            color: $bluemine-900;
            background-color: $bg-dark;
        }
    }
}
