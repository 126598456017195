@import "../../../assets/styles/global.scss";

.not__found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    p {
        color: $primary;
        font-size: $font-lg;
    }
}