@import "../../../../../../assets/styles/global.scss";

#setup__schedules {
    flex: 1;
    .schedule-options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        .option {
            @include borderedBox;
            height: 270px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            align-content: space-between;
            transition: all .1s ease-in-out;
            cursor: pointer;
            &.selected-option {
                background-color: $geyser-50;
            }
            &:hover {
                background-color: rgba($geyser-50, .5);
            }
            p {
                color: $primary;
                font-size: 1.5rem;
                line-height: 24px;
                text-align: center;
            }
            &--2 p {
                color: $geyser-700;
            }
        }
    }

    .input-select-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 5px;
        margin-bottom: 20px;
        label {
            color: $geyser-800;
            font-size: 1.2rem;
            line-height: 18px;
        }
    }

    .days {
        display: grid;
        align-content: space-between;
        row-gap: 30px;
        column-gap: 50px;
        grid-template-columns: repeat(2, 180px);
        justify-content: space-between;
        margin-top: 35px;
        .day-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .day {
                margin-right: 50px;
                font-size: 1.5rem;
                color: geyser-900;
                width: 70px;
            }
        }
    }

    .schedule-config {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
        >.day-item__schedule {
            display: grid;
            row-gap: 10px;
            @keyframes contentAppear {
                from { opacity: 0; }
                to { opacity: 1; }
            }
            >.item-header {
                display: flex;
                align-items: center;
                .title-wrapper {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    >h5 {
                        font-size: 1.9rem;
                        color: $geyser-700;
                        font-weight: 500;
                        margin-right: 10px;
                    }
                    .arrow-icon {
                        font-size: 2.5rem;
                        margin-right: 5px;
                        color: $geyser-700;
                    }
                    .btn {
                        animation: contentAppear .1s ease-in-out;
                    }
                }
            }
            >.item-content {
                width: 60%;
                animation: contentAppear .1s ease-in-out;
                margin: 0 10px;
                .form-group {
                  display: flex;
                  flex-direction: column;
                  &:first-child {
                    margin-bottom: 10px;
                  }
                  label {
                    font-size: 1.2rem;
                    color: $geyser-800;
                    text-transform: capitalize;
                  }
            
                  &.group-time {
                    flex-direction: row;
                    > * + * {
                      margin-left: 20px;
                    }
                    > div {
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      > * {
                        margin-top: 5px;
                      }
            
                      &:first-of-type {
                        margin-right: auto;
                      }
                    }
                  }
                }
                .break-title {
                  font-size: 1.5rem;
                  color: $geyser-700;
                  margin: 28px 0;
                  font-weight: 400;
                  line-height: 19px;
                }
                .breaks-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    .add-break__button {
                        margin-top: 0 !important;
                    }
                }
                .break-list {
                  display: flex;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  .break-item {
                    display: flex;
                    width: 115px;
                    padding: 25px 10px;
                    flex-direction: column;
                    border: solid 1px $stroke-color;
                    transition: all .15s ease-in;
                    cursor: pointer;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    justify-content: center;
                    align-items: center;
                    animation: breakAppear .15s ease-in-out;
                    &:not(:nth-child(3n)) {
                      margin-right: 15px;
                    }
                    &:hover {
                      background-color: $grey-100;
                    }
                    &:hover .title {
                      color: $primary;
                    }
                    >.title {
                      font-size: 1.3rem;
                      margin-bottom: 5px;
                      color: $primary;
                      line-height: 19px;
                      color: $geyser-700;
                      font-weight: 500;
                    }
                    >.time {
                      font-size: 1.2rem;
                      color: $geyser-400;
                    }
                    @keyframes breakAppear {
                      from { opacity: 0 }
                      to { opacity: 1 }
                    }
                  }
                }
                .add-break__button {
                  margin-top: 10px;
                  margin-left: -6px;
                  font-weight: 500;
                  svg {
                    font-size: 1.3rem;
                  }
                }
                .action-buttons {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 2px;
                  .done-button {
                    font-weight: 500;
                  }
                }
            }
        }
    }
}
