@import "../../../../../../assets/styles/global.scss";

.setup__business {
    .categories,
    .subs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 250px);
        gap: 2rem;
        place-content: center;
        border-radius: 4px;
        > div {
            border: 1px solid $stroke-color;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            > p {
                color: $geyser-700;
                text-align: center;
                font-size: $font-lg;
            }

            &.active,
            &:active,
            &:hover,
            &:focus {
                border-color: transparent;
                background-color: $light-info;
                > p {
                    color: $primary;
                }
            }
        }
    }

    //override template and div size.
    .subs {
        grid-template-columns: repeat(3, 175px);
        > div {
            height: 70px;
        }
    }
}
