@import "../../../../../assets/styles/global.scss";

.notes {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    @media #{$md-mobile-screen} {
        padding: 0 12px;
    }
    >button {
        align-self: flex-start;
        margin-bottom: 16px;
        svg {
            font-size: 1.2rem;
        }
    }

    .notes__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 236px;
        background-color: $white;
        overflow-y: auto;

        > .notes__container {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            overflow-y: auto;

            > h6 {
                color: $geyser-900;
                padding: 10px;
                border-bottom: 1px solid $stroke-color;
                margin-bottom: 1.4rem;
                @include create-mq-string($lg-desktop-screen) {
                    margin-bottom: 2.4rem;
                }
            }

            > .notes__group {
                width: 100%;

                > .loading {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }

                > .message {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 236px;
                    text-align: center;

                    > p {
                        color: $geyser-900;
                        font-size: $font-md;
                    }
                }
            }
        }
    }

    .input {
        margin-top: 18px;
        width: 100%;
        position: relative;
        > input {
            width: 100%;
            border: none;
            border-bottom: 1px solid $stroke-color;
            border-radius: 0px;
        }
        .add__button {
            position: absolute;
            right: 0px;
            top: 20%;
            svg {
                font-size: 2rem;
                color: $geyser-900;
            }
        }
    }
}
