@import "../../../../../assets/styles/global.scss";

.notifications__manage {
    width: 100%;
    background-color: $bg-dark;
    padding: 12px 20px;
    display: flex;
    overflow-y: auto;
    height: 100vh;
    flex-direction: column;
    position: relative;
    @keyframes dislay-content {
        from {
            transform: translateY(16px);
        }
        to {
            transform: translateY(0);
        }
    }
    &__service {
        animation: dislay-content .15s ease-in-out;
        margin-top: 32px;
        &__option {
            margin-top: 20px;
            >p {
                margin-bottom: 12px;
            }
        }
        &__check {
            display: flex;
            align-items: center;
            >label {
                margin-right: 20px;
                display: flex;
                align-items: center;
                span {
                    margin-left: 8px;
                }
            }
        }
    }
}
