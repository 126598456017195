@import "../../../../../assets/styles/global.scss";

thead {
    .table__head_title {
        text-align: left;
        position: relative;
        height: 40px;
        .cell__content {
            display: flex;
            align-items: center;
            height: 100%;
            .indicator {
                position: absolute;
                cursor: pointer;
                width: 24px;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
                z-index: 0;
                &:hover + .more__actions {
                    width: 30px;
                }
            }
            .more__actions {
                transition: all 0.2s ease-in;
                margin-left: -10px;
                height: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 10px;
                width: 0;
                z-index: 5;
                svg {
                    font-size: 2.2rem;
                    color: $geyser-500;
                }
                &:hover {
                    width: 30px;
                }
                .menu__button {
                    button {
                        z-index: 5;
                        .svg {
                            font-size: 2.3rem;
                        }
                    }
                }
                .menu__content {
                    position: absolute;
                    padding: 16px;
                    top: 104%;
                    left: 0;
                    width: 180px;
                    z-index: 10;
                    border-radius: 4px;
                    background-color: $white;
                    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2), 0px 10px 14px rgba(0, 0, 0, 0.14), 0px 4px 18px rgba(0, 0, 0, 0.12);
                    button:first-child {
                        margin-bottom: 8px;
                    }
                }
            }
            .add__service svg {
                font-size: 1.4rem;
            }
        }
        &:not(:first-child) {
            border-top: none !important;
        }
    }
}

.table__tbody, .table__tr {
    &:hover .remove__service__line {
        opacity: 1;
    }

    td {
        border: solid 1px $stroke-color;
        padding: 4px 12px;
        animation: diplayLines 0.1s ease-in;
        vertical-align: middle;
        &.cell__focused {
            border: solid $primary 1px;
        }
        &.input__focus {
            z-index: 1;
        }
        @keyframes diplayLines {
            from {
                opacity: 0.5;
            }
            to {
                opacity: 1;
            }
        }
    }

    .minimized__services {
        border: solid $white 0px;
        td {
            border: solid $white 0px;
            font-size: $font-sm;
            color: $geyser-500;
            padding: 5px 12px;
            cursor: pointer;
        }
    }
    .service__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        .remove__service__line {
            transition: all 0.2s ease-in;
            transform: translateX(-10px);
            width: 0;
            &:hover {
                width: 35px;
            }
            svg {
                font-size: 2.2rem;
                color: $geyser-500;
            }
        }
        .indicator {
            position: absolute;
            cursor: pointer;
            width: 20px;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 0;
            &:hover + .remove__service__line {
                width: 35px;
            }
        }
    }
}

.table__tbody {
    .service__name {
        position: relative;
        // width:  50%;
    }
    .duration {
        width: 18%;
        text-align: center;
        .input__time {
            border: none !important;
            input {
                padding: 0;
            }
        }
    }
    .locations {
        padding: 0;
    }
    .buffer {
        width: 22%;
        position: relative;
        padding: 0;
        > span {
            position: absolute;
            right: 5px;
            top: 32%;
            svg {
                font-size: 1.7rem;
                color: $geyser-500;
            }
        }
    }
    .price {
        width: 15%;
        text-align: right;
        padding-left: 0;
        #input__label {
            padding: 0;
            width: 70px;
            border: none !important;
            input {
                padding: 0;
                border: none !important;
                text-align: right;
                &::placeholder {
                    color: $geyser-300;
                }
            }
        }
    }
}

.input__text {
    padding: 0;
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
    &::placeholder {
        color: $geyser-300;
    }
}

.input__selection {
    width: 100%;
    border: none !important;
    span {
        padding: 0;
        svg {
            margin-right: 16px;
        }
    }
}

#modal__confirm {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: transparent;
    display: grid;
    align-items: center;
    justify-content: center;
    .modal__content {
        width: 312px;
        padding: 42px 36px;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2), 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        background: $white;
        > h6 {
            color: $geyser-900;
            font-weight: 400;
        }
        > p {
            color: $geyser-500;
            font-size: $font-lg;
            margin-top: 36px;
        }
        .buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 36px;
            > button {
                margin-left: 18px;
            }
        }
    }
}
