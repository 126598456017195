@import "../../../../../assets/styles/global.scss";

.grid__time {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 180px;
    row-gap: 36px;

    > div {
        display: flex;
        flex-direction: column;

        > .input__wrapper {
            margin-bottom: 36px;
            > .check__wrapper {
                display: flex;
                align-items: center;
                > span {
                    font-size: $font-lg;
                    color: $geyser-900;
                    margin-right: 36px;
                    width: 80px;
                }
            }
            > .time {
                display: flex;
                > *:last-child {
                    margin-left: 20px;
                }
            }
        }
    }
}
