@import "../../../../../assets/styles/global.scss";


.popup__rescheduling {
    margin-bottom: 48px;
    animation: appear .2s ease-in-out;
    @keyframes appear {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @media #{$sm-mobile-screen} {
        padding: 0 12px;
    }

    .step__content {
        min-height: 200px;
        margin: 10px 0px 20px 0;
        margin-top: 24px;
    }

    .dates__group {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
        row-gap: 16px;
        @media #{$sm-mobile-screen} {
            grid-template-columns: repeat(4, 1fr);
        }
        .date {
            padding: 20px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px $stroke-color;
            border-radius: 4px;
            transition: all 0.2s ease-in-out;
            flex-direction: column;
            background-color: $white;
            cursor: pointer;
            @media #{$sm-mobile-screen} {
                padding: 20px 12px;
            }

            > span {
                font-size: $font-lg;
                color: $geyser-900;

                &:last-of-type {
                    font-size: $font-md;
                    color: $geyser-500;
                }
            }

            &:hover,
            &:active,
            &.active {
                background-color: $geyser-50;
                span {
                    color: $bluemine-700;
                    &:last-of-type {
                        font-size: $font-md;
                        color: $bluemine-500;
                    }
                }
            }
        }
        > .box-disabled {
            background-color: rgba($stroke-color, 0.6);
            cursor: wait;
        }
        > .box-loader {
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-name: anima;
            background: $stroke-color;
            background: linear-gradient(to right, #eee 2%, #ddd 10%, #eee 20%);
            background-size: 1300px;
            @keyframes anima {
                0% {
                    background-position: -650px 0;
                }
                100% {
                    background-position: 650px 0;
                }
            }
        }
    }

    .categories, .services {
        min-height: 200px;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        grid-gap: 12px;
        row-gap: 16px;
        @media #{$sm-mobile-screen} {
            grid-template-columns: repeat(4, 1fr);
        }
        .service__item {
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px $stroke-color;
            border-radius: 4px;
            height: 80px;
            padding: 8px;
            cursor: pointer;
            background-color: $white;
            > span {
                text-align: center;
                font-size: $font-lg;
                color: $geyser-700;
            }

            &:hover,
            &:active,
            &.active {
                background-color: $geyser-50;
                > span {
                    color: $bluemine-700;
                    font-weight: 400;
                }
            }
        }
    }

    .calendar__wrapper {
        position: relative;
        width: fit-content;
        >button {
            margin-top: 12px;
        }
        > .calendar__content {
            transition: all 0.25s ease-in-out;
            position: absolute;
            top: 100%;
            z-index: 5;
            height: auto;
            margin-top: 1rem;
            max-width: 300px;
            animation: calendarAppear 0.1s ease-in;

            @keyframes calendarAppear {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
    }

    .confirmation__view {
        margin-top: 20px;
        &__content {
            >div {
                display: flex;
                align-items: center;
                margin: 16px 0;
                &:first-of-type {
                    margin-top: 0;
                }
                span {
                    font-size: $font-sm;
                    color: $geyser-700;
                    width: 7rem;
                    @media #{$sm-mobile-screen} {
                        width: 120px;
                    }
                }
                p {
                    font-size: $font-lg;
                    color: $geyser-900;
                    a {
                        color: $primary;
                    }
                }
            }
        }
        &__new__dates {
            margin-top: 12px;
            span {
                font-size: $font-sm;
                color: $geyser-700;
                width: 120px;
            }
            p {
                font-size: $font-lg;
            }
            &__header {
                display: none;
                @media #{$sm-mobile-screen} {
                    display: grid;
                    grid-template-columns: 120px 1fr 50px 1fr;
                }
                >p {
                    text-align: center;
                }
            }
            &__values {
                margin-top: 16px;
                display: grid;
                grid-template-columns: 1fr;
                @media #{$sm-mobile-screen} {
                    justify-items: center;
                    align-items: center;
                    grid-template-columns: 120px 1fr 50px 1fr;
                }
                svg {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .slots__group {
        min-height: 10rem;

        > p {
            display: flex;
            font-size: $font-lg;
            color: $geyser-500;
        }

        > .slots {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-gap: 12px;
            @media #{$sm-mobile-screen} {
                grid-template-columns: repeat(4, 1fr);
            }

            button {
                min-width: auto !important;
            }

            > .slot {
                transition: all 0.2s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px $stroke-color;
                border-radius: 4px;
                height: 56px;
                cursor: pointer;
                background-color: $white;
                > span {
                    text-align: center;
                    font-size: $font-lg;
                    color: $geyser-700;
                }

                &:hover,
                &:active,
                &.active {
                    background-color: $geyser-50;
                    > span {
                        color: $bluemine-700;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .success__view {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 0;
        svg {
            font-size: 10rem;
            color: $elm-500;
        }
        h6 {
            margin: 16px 0;
            font-weight: 500;
            text-align: center;
        }
        p {
            text-align: center;
        }
    }

    .actions__button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;
        button {
            &:last-of-type {
                margin-left: 28px;
            }
        }
    }
}
