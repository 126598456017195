@import "../../../assets/styles/global.scss";

#empty__block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .empty__block--container {
        // default type = "column"
        width: 100%;
        .eb__column {
            .eb__svg {
                width: clamp(50%, 75vw, 80%);
                max-height: 50vh;
                display: flex;
                margin: 0 auto;
                padding: 0;
                margin-bottom: 70px;
            }
            .eb__title {
                display: block;
                user-select: none;
                color: $grey-500;
                text-align: center;
                margin-bottom: 10px;
                white-space: pre-line;
                line-height: $lh-24;
                font-weight: 400;
                width: 100%;
                font-size: $font-2xl;
            }
            .eb__description {
                width: 100%;
                display: block;
                font-size: $font-sm;
                user-select: none;
                color: $geyser-500;
                text-align: center;
            }

            .empty__block-actions {
                display: flex;
                justify-content: center;

                .eb__button {
                    cursor: pointer;
                    width: auto;
                    font-weight: 500;
                }
            }

            > * {
                &:last-child {
                    margin-top: 20px;
                }
            }
        }

        // modify for row type.
        .eb__row {
            .eb__svg {
                width: 95%;
                height: 100%;
                max-width: 450px;
                min-width: 150px;
                display: block;
                margin-left: auto;
            }

            .eb__content {
                display: flex;
                flex-direction: column;
                height: 100%;
                max-width: 450px;
                margin: 0 auto;
                justify-content: center;
            }

            .eb__title {
                // flex box center.
                margin-top: 20px;
                color: $geyser-900;
                white-space: pre-line;
                line-height: $lh-48;
                font-weight: 400;
                width: 100%;
                font-size: $font-3xl;
                text-align: center;
                @include create-mq-string($sm-desktop-screen) {
                    text-align: left;
                }
            }

            .eb__description {
                font-size: $font-lg;
                user-select: none;
                color: $geyser-500;
                text-align: center;
                margin: 20px 0 20px 0;

                @include create-mq-string($lg-mobile-screen) {
                    text-align: left;
                }
            }

            .empty__block-actions {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 4rem;
                @include create-mq-string($sm-desktop-screen) {
                    justify-content: flex-start;
                }
                .eb__button {
                    width: auto;
                    margin: 0 auto;
                    @include create-mq-string($lg-mobile-screen) {
                        margin: 0 0;
                    }
                }
            }
        }
    }

    &.empty__block--column {
        .empty__block--container {
            div {
                .eb__svg {
                    width: clamp(50%, 75vw, 80%);
                    max-height: 30vh;
                    margin-bottom: 0px;
                }
                .eb__title {
                    margin: 10px 0;
                    margin-top: 30px;
                }
            }
        }
    }
}
