@import "../../../../assets/styles/global.scss";

.popup__create__event {
    .items__inputs {
        margin-top: 20px;
    }
    .event__time {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }
    .radio__input__wrapper {
        display: grid;
        margin-top: 16px;
        grid-template-columns: 40px auto auto;
        justify-content: flex-start;
        align-items: center;
        column-gap: 16px;
        span {
            display: flex;
            svg {
                color: $geyser-500;
                font-size: 1.6rem;
            }
        }
    }
}

.popup__actions {
    display: flex;
}