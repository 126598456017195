@import "../../../../../../assets/styles/global.scss";

#setup__employee__services {
  .category {
    margin-bottom: 30px;
    .header {
      display: flex;
      align-items: center;
      cursor: pointer;
      >p {
          font-size: 1.9rem;
          color: $geyser-700;
          font-weight: 500;
          margin-right: 7px;
      }
      .arrow-icon {
          font-size: 2.5rem;
          margin-right: 5px;
          color: $geyser-700;
      }
    }

    .services-list {
      margin-top: 25px;
      animation: contentAppear .1s ease-in-out;
      margin-left: 30px;
      @keyframes contentAppear {
        from { opacity: 0; }
        to { opacity: 1; }
      }
      >.service-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 15px;
        color: $geyser-500;
        font-size: 1.5rem;
        .check-input {
          margin-right: 10px;
        }
      }
    }
  }
  .guide-skip-button {
    position: absolute;
    bottom: 0;
    right: 5px;
  }
}
