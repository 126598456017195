@import "../../../../../assets/styles/global.scss";

.appointment__popup__container {
    position: relative;
    display: grid;
    .appointment__details {
        margin-bottom: 48px;
        transition: all .2s ease-in-out;
        @media #{$md-mobile-screen} {
            padding: 0 12px;
        }
        &__hidden {
            position: absolute;
            transform: translateX(-1000px);
        }
        .appointment__loader {
            display: flex;
            justify-content: center;
            padding: 36px;
        }
        &__content {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            @media #{$md-mobile-screen} {
                grid-template-columns: 1fr .5fr;
                grid-template-rows: auto;
            }
        }
        &__infos__column {
            >div {
                display: flex;
                align-items: center;
                margin: 16px 0;
                &:first-of-type {
                    margin-top: 0;
                }
                span {
                    font-size: $font-sm;
                    color: $geyser-700;
                    width: 8rem;
                    @media #{$md-mobile-screen} {
                        width: 120px;
                    }
                }
                p {
                    font-size: $font-lg;
                    color: $geyser-900;
                    a {
                        color: $primary;
                    }
                }
            }
        }
        &__actions__column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .activity__button {
                font-weight: 500;
                margin-top: 10px;
                svg {
                    font-size: 1.1rem;
                }
            }
            .archive__button {
                margin-top: 32px;
                svg {
                    font-size: 1.8rem;
                }
            }
        }
        &__action__buttons {
            margin-top: 24px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            button {
                margin: 4px 0;
            }
            @media #{$md-mobile-screen} {
                flex-direction: row;

                button {
                    margin: 0;
                    &:first-of-type {
                        margin-right: auto;
                    }
                    &:last-of-type {
                        margin-left: 28px;
                    }
                }
            }
        }
    }

    .notes__wrapper {
        animation: slide .2s ease-in-out;
        &__hidden {
            display: none;
        }
        @keyframes slide {
            from {
                transform: translateX(1000px);
            }
            to {
                transform: translateX(0);
            }
        }
    }
}
