@import "../../../assets/styles/global.scss";

#header {
    .header__container {
        display: flex;
        flex-direction: column;
        max-height: 96px;
    }
    

    background-color: $bluemine-900;
    box-shadow: $dp-6;
    
    .header__container {
        height: auto;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .navigation-wrapper, .mobile-menu {
            width: 100%;
        }
        @media #{$lg-mobile-screen} {
            height: 56px;
            flex-direction: row;
            padding: 12px 96px;
            .navigation-wrapper, .mobile-menu {
                width: unset;
            }
        }
    }

    .header__app-name {
        display: flex;
        align-items: center;
    }

    .header_action {
        box-shadow: $dp-4;
        border: none;
        color: $bluemine-900;
    }

    .header_icon_actions__wrap {
        align-items: center;
        .header_icon_actions {
            padding-right: 12px;
            height: 24px;
            &__visible {
                border-right: solid 1px $white;
            }
            * {
                // Make sure all icons are the right color
                color: $bg-dark;
            }
        }
    }
    .notification_action {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        align-self: center;
        justify-self: center;
        &__pdright {
            padding-right: 0;
        }
        >button {
            position: relative;
            svg {
                font-size: 1.8rem;
            }
            .notifications__length {
                background-color: $valencia-500;
                font-size: 7px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: -4px;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// #header {
//     width: 100%;
//     height: $headerHeight;
//     position: relative;
//     display: block;
//     z-index: 500;
//     background-color: $bluemine-900;
//     box-shadow: $dp-6;
//     // padding: 0;

//     // @media #{$lg-mobile-screen-only} {
//     //     padding-left: 1rem;
//     // }

//     .container {
//         height: 100%;
//         .row {
//             height: 100%;
//             align-items: center;
//         }
//     }

//     &.loading__component-nav {
//         overflow: hidden;
//     }

//     .header__app-name {
//         z-index: 2;
//     }

//     //for small devices dropdown button.
//     .btn__dropheader {
//         display: flex;
//         margin: 0 0 0 auto;
//         font-size: 3em;
//         padding: 0;
//         background: none;
//         border: none;
//         color: $element-color;
//         @include transition;

//         &:hover,
//         &:focus,
//         &:active {
//             color: $element-color;
//         }
//     }

//     .header__cols {
//         background-color: $bluemine-900;
//         z-index: 1;
//         display: flex;
//         position: absolute;
//         top: 100%;
//         left: 0;
//         height: auto;
//         align-items: center;
//         > .container {
//             height: 100%;
//             display: flex;
//             align-items: center;
//         }

//         // custom app_actions outside app__actions for small devices.
//         .app__actions-custom {
//             margin-top: 2rem;
//             > * + * {
//                 margin-top: 2rem;
//             }
//         }

//         .app__actions {
//             display: flex;
//             align-self: flex-end;
//             align-items: center;
//             justify-content: flex-end;
//             height: 100%;
//             > * {
//                 margin: auto 0;
//             }

//             .add-new__action {
//                 background: white;
//                 box-shadow: $dp-1;
//                 svg {
//                     font-size: 1.2rem;
//                     margin-right: 5px;
//                 }
//             }

//             //all inputs should use same padding top/bottom as button.
//             input {
//                 padding: $buttonPaddingY $inputPaddingX !important;
//             }

//             //overide .round__search to use correct padding.
//             .round__search {
//                 background-color: transparent !important;
//                 width: 150px;
//                 * {
//                     background-color: transparent !important;
//                 }
//                 > span[role="button"] {
//                     padding: $buttonPaddingY $inputPaddingX !important;
//                 }
//             }

//             // space between all containers. (gap)
//             > * + * {
//                 margin-left: 10px;
//             }
//             //container of custom action for bigger devices.
//             > div {
//                 > * + * {
//                     margin-left: 10px;
//                 }
//             }

//             &.show {
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 padding-top: 12px;
//                 animation-name: dropdownHeaderCols;
//                 animation-fill-mode: forwards;
//                 animation-duration: 0.3s;
//                 animation-iteration-count: 1;

//                 @keyframes dropdownHeaderCols {
//                     0% {
//                         top: -100%;
//                     }

//                     100% {
//                         top: 100%;
//                     }
//                 }
//             }
//         }

//         //group of navigation links
//         .header__nav {
//             margin: 0 auto;
//             display: flex;
//             flex-direction: row;
//             padding: 12px 0;
//             gap: 8px;
//             @media #{$tablet-screen} {
//                 gap: 16px;
//                 padding: 0;
//             }
//             @media #{$sm-desktop-screen} {
//                 gap: 36px;
//             }

//             * {
//                 user-select: none;
//             }

//             &-link {
//                 min-width: 92px;
//                 width: 100%;
//                 text-align: center;
//                 padding: 4px 8px;
//                 border-radius: 16px;
//                 color: $geyser-200;
//                 &.active {
//                     color: $bluemine-900;
//                     background-color: #f9f9fa;
//                 }
//             }
//         }

//         @include create-mq-string($sm-desktop-screen) {
//             padding: 0;
//             position: unset;
//             display: flex;

//             // we donot want the container to behave like a container with padding for bigger screens 992px >
//             > .container {
//                 opacity: unset;
//                 max-width: unset !important;
//                 width: 100%;
//             }
//         }
//     }
// }
