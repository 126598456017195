@import "../../../assets/styles/global.scss";

.loading__app--fallback {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 600;
    .line {
        background-color: $bluemine-200;
        height: 2px;
        box-shadow: 0px 5px 10px rgba($bluemine-200, 0.3);
    }
    animation: 2s moveToRight cubic-bezier(0, 0.95, 1, -0.01) forwards;
}

@keyframes moveToRight {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
