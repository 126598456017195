@import "../../../../../assets/styles/global.scss";

.custom__select {
  min-width: 100px;
  // position: relative;
  display: inline-flex;
  flex-direction: column;
  font-size: $font-sm;
  letter-spacing: $ls-3xl;
  color: $geyser-800;
  border-radius: 4px;
  border: 1px solid $stroke-color;
  height: auto;
  outline: none;

  span[role="button"] {
    background-color: $white;
    @include unSelect;
    position: relative;
    padding: $inputPadding;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    svg:not(.arrow) {
      margin: 0 4px;
    }

    > .arrow {
      display: block;
      @include transition;
      font-size: 1.5em;
      line-height: 0;
      color: $geyser-100;
      margin-left: auto;
    }

    &.active {
      > .arrow {
        transform: rotateX(180deg);
      }
    }

    &::before {
      @include transition;
      content: attr(data-placeholder);
      color: $geyser-100;
      display: block;
    }
  }

  > .custom__select-wrapper {
    @extend %dropdownHidden;

    > ul {
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
      margin-top: 2px;
      width: 100%;
      z-index: 1;
      background-color: $white;
      border: 1px solid $stroke-color;
      border-radius: 0px;
      flex-grow: 1;
      flex-basis: 1;
      flex-direction: column;
      padding: 0px;
      list-style: none;
      display: block;
      max-height: 180px;
      overflow-y: auto;

      > li {
        padding: $inputPadding;
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          margin: 0 4px;
        }
        label {
          margin-right: 7px;
          z-index: 7;
          input {
            z-index: 7;
          }
        }

        &.selected,
        &:active,
        &:hover {
          background-color: $light-info;
        }

        &::after {
          content: ".";
          opacity: 0;
        }
      }
    }
  }

  &.show {
    > .custom__select-wrapper {
      @extend %dropdownShow;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
  &.no-border {
    border: none;
    border-bottom: solid 2px $stroke-color;
    border-radius: 0;
  }

  &:hover,
  &:active,
  &:focus {
    span[role="button"] {
      > svg {
        color: $geyser-800;
      }
      &::after {
        color: $geyser-800;
      }
    }
  }
}
