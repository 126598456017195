@import "../../../../assets/styles/global.scss";
// For popup configuration step for the first time.

.custom__popup-first-setup {
    overflow: hidden;
}

#popupFirstSetup {
    background-color: $white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 700px !important;
    min-height: 500px !important;
    max-height: 752px !important;
    padding: 32px 42px !important;

    //header with title and next button
    .pcfs-header {
        display: flex;
        flex-direction: column;
        user-select: none;
    }

    // loading steps bar
    .percentage {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        margin-top: auto;
        > * + * {
            margin-left: 36px;
        }
    }

    .guide__content {
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 36px;
        margin-bottom: 38px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;

        #stepConfirm {
            .form-group {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;
                label {
                    font-size: $font-sm;
                }
            }
        }
        > .tour__completed {
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;

            > div {
                display: flex;
                margin: 0 auto;
                width: 65%;
                > svg {
                    width: 100%;
                }
                > h6 {
                    color: $primary;
                    display: grid;
                    grid-template-columns: auto auto;
                    column-gap: 10px;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    width: 100%;
                    svg {
                        font-size: 28px;
                    }
                }
            }
        }
    }
}
