@import "../../../../../assets/styles/global.scss";

.search__wrapper__component {
    .search__wrapper {
        min-height: 240px;
        &__content {
            position: relative;
            >div {
                width: 100%;
            }
            >p {
                font-size: $font-sm;
                color: $geyser-800;
            }
            .results__wrapper {
                box-shadow: $dp-4;
                border-radius: 4px;
                background-color: $white;
                z-index: 10;
                position: absolute;
                left: 0;
                width: 100%;
                top: 104%;
                .loader__wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 2rem;
                }
                .button__wrapper {
                    button {
                        justify-content: flex-start;
                        padding: 15px 8px !important;
                        width: 100%;
                        svg {
                            font-size: 1.5rem;
                            margin-right: 6px;
                        }
                    }
                }
            }
        
            .search__contact__entities {
                height: auto;
                overflow-y: auto;
                max-height: 256px;
                > .search__contact__item {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    padding: 1rem .8rem;
                    // @include transition;
                    &:hover,
                    &:focus,
                    &:active,
                    &.selected {
                        background-color: $geyser-50;
                        outline: none;
                    }
            
                    > span {
                        max-width: 50%;
                        >span {
                            margin-top: -6px;
                        }
                    }
                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
            
                    //mark text
                    mark {
                        background-color: transparent !important;
                        color: $valencia-500;
                    }
                }
            }
        }
        .customer__info {
            display: grid;
            margin-top: 20px;
            gap: 4px;
            p {
                font-size: $font-md;
                color: $geyser-700;
            }
        }
    }

    .create__client__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        column-gap: 20px;
        input {
            width: 100% !important;
        }
    }
}
