@import "../../../../../../assets/styles/global.scss";

.services {
    > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        > li {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 16px 0;

            > h6 {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                cursor: pointer;

                > svg {
                    @include transition;
                    transform: rotateX(180deg);
                }

                &.active {
                    svg {
                        transform: rotateX(0deg);
                    }
                }
            }
            > ul {
                > li {
                    padding: 8px 20px;
                    letter-spacing: $ls-xl;
                    font-weight: 400;
                    cursor: pointer;

                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    &:hover,
                    &:active {
                        color: $geyser-900;
                    }
                }
            }
        }
    }

    > .form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 10px;

        > .form__wrapper {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            > label {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                font-size: $font-sm;
                color: $grey-300;

                > span {
                    display: flex;
                    align-items: center;
                    > svg {
                        margin-left: 10px;
                        font-size: 18px;
                    }
                }
            }

            > ul {
                display: flex;
                flex-direction: column;
                max-height: 180px;
                overflow-y: auto;
                li {
                    color: $geyser-500;
                    font-size: $font-sm;
                    display: grid;
                    grid-template-columns: auto 1fr;
                    column-gap: 8px;
                    row-gap: 8px;
                    align-items: center;
                }
            }

            &.hidden {
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    .services__creation {
        .actions__buttons {
            margin-bottom: 6px;
            margin-left: 8px;
        }
        .services__table {
            width: 100%;
            border-collapse: separate;
        }
    }
}
