@import "../../../assets/styles/global.scss";

#footer {
  p {
    @include setFontSize(md);
    font-family: "Poppins Regular", sans-serif;
    line-height: 19px;
    text-align: right;
    text-transform: capitalize;
    color: #7f7f7f;
    span {
      color: $primary;
    }

    &.loading__component {
      @include componentLoader;
      width: 124px;
      height: 10px;
      background-color: #eaeaea;
      border-radius: 8px;
      display: block;
      margin-left: auto;
      margin-bottom: 5px;
    }
  }
}
