@import "../../../assets/styles/global.scss";

$sidebar-width: 24rem;
$sidebar-width-mobile: 100vw;

.sidebar {
    width: 100%;
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    border-right: 1px solid $stroke-color;
    box-shadow: $dp-1;
    position: relative;
    overflow: hidden;
    button {
        box-shadow: none;
    }

    &__account__settings__container {
        position: relative; //absolute
        left: 0;
        @include transition;
        width: 100%;
        &.hide {
            left: -$sidebar-width-mobile;
            @media #{$lg-mobile-screen-only} {
                left: -$sidebar-width;
            }
        }
    }

    &__head {
        @include transition;
        padding: 3.2rem 0 0 0;

        &__content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            position: relative;
            @include transition;
        }
        .organization__switch__container {
            margin: 0 2rem;
            display: flex;
            align-items: baseline;
            gap: 0.8rem;
            grid-gap: 0.8rem; // for old browser support like safari mobile under iOS 14.5 thanks Apple
            font-size: 1.2rem;
            &__name {
                color: $geyser-900;
                line-height: 2.4rem;
                margin-bottom: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            svg {
                cursor: pointer;
                line-height: 1.2rem;
                transform: scale(1.8);
                color: $geyser-700;
            }
        }
        .organization__change__container {
            margin: 0 2rem;
            margin-top: 1rem;
            display: flex;
            padding-bottom: 2.6rem;
            border-bottom: solid 1px $stroke-color;
            button {
                margin-left: -6px;
                svg {
                    font-size: 2rem;
                }
            }
        }

        @media #{$lg-desktop-screen} {
            .organization__switch__container {
                &__name {
                    margin-bottom: 0.8rem;
                }
            }
        }
    }

    &__organizations__return {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        grid-gap: 0.8rem;
        cursor: pointer;
        @include transition;
        padding: 0 2rem;
        &:hover {
            color: $primary;
        }
        &__container {
            @include transition;
            position: absolute;
            height: 8rem;
            left: 0;
            &.hide {
                left: $sidebar-width-mobile;
                @media #{$lg-mobile-screen-only} {
                    left: $sidebar-width;
                }
            }
        }
        &__text {
            color: $primary;
            font-size: $font-md;
        }
        svg {
            color: $primary;
            font-size: $font-lg;
        }
    }

    &__navigation {
        display: grid;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;

        &__organizations__list {
            display: grid;
            position: absolute;
            @include transition;
            left: 0;
            &.hide {
                left: $sidebar-width-mobile;
                @media #{$lg-mobile-screen-only} {
                    left: $sidebar-width;
                }
            }
        }
        &__organizations__item,
        &__organizations__add {
            @include transition;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 1.4rem;
            grid-gap: 1.4rem;
            font-size: $font-lg;
            color: $geyser-900;
            padding: 1.2rem 2rem;
        }

        &__organizations__item {
            width: $sidebar-width;
            padding: 1.2rem 2rem;
            &:hover {
                background-color: $light-info;
            }
            &.active {
                background-color: $light-info;
                color: $primary;
                border-left: solid 4px $primary;
            }
        }

        &__organizations__add {
            font-size: 1.4rem;
            color: $primary;
            font-weight: 400;
        }

        &__links__container {
            padding: 5.2rem 0 4rem 0;
            display: flex;
            flex-direction: column;

            position: relative;
            left: 0;
            @include transition;

            &.hide {
                left: -$sidebar-width-mobile;
                @media #{$lg-mobile-screen-only} {
                    left: -$sidebar-width;
                }
            }
        }

        &__links__group {
            display: flex;
            flex-direction: column;
            &:not(:first-child) {
                margin-top: 4rem;
            }

            &.hide {
                left: -$sidebar-width-mobile;
                @media #{$lg-mobile-screen-only} {
                    left: -$sidebar-width;
                }
            }
            >a {
                text-align: left;
            }
        }

        &__link {
            font-size: $font-lg;
            color: $geyser-900;
            padding: 1.2rem 2rem;
            @include transition;
            display: flex;
            align-items: center;
            svg {
                color: $geyser-900;
                margin-right: 1.2rem;
                font-size: 1.9rem;
            }
            &:hover {
                background-color: $geyser-50;
            }
            &__active {
                color: $primary;
                position: relative;
                color: $primary;
                border-left: solid 4px $primary;
                background-color: $geyser-50;
                &::after {
                    position: absolute;
                    text-align: center;
                    width: 3rem;
                    height: 1.8rem;
                }
                svg {
                    color: $primary;
                }
            }
        }
    }

    &__footer {
        // position: sticky;
        margin-top: auto;
        padding: 2rem;
        background: $white;
        bottom: 0;
        z-index: 2;
        width: 100%;
        &__user__info__container {
            display: flex;
            align-items: center;
            > a {
                > .avatar {
                    cursor: pointer;
                }
            }
        }
        &__user__info {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 5px;
            margin-top: -4px;
            * {
                line-height: 1;
            }
            > a {
                font-size: $font-lg;
                color: $geyser-900;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            button {
                margin-top: auto;
                font-size: $font-md;
                svg {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
