@import "../../../../../assets/styles/global.scss";

.notifications__item {
    padding: 12px 16px;
    display: flex;
    cursor: pointer;
    &:first-of-type {
        padding-top: 16px;
    }
    .notification__title {
        flex: 1;
        display: flex;
        flex-direction: column;
        >p {
            font-size: $font-lg;
            color: $bluemine-900;
            margin-bottom: 4px;
            transition: all .2s ease-in-out;
        }
        >span {
            font-size: $font-md;
            color: $bluemine-700;
        }
    }
    &__selected {
        background-color: $geyser-50;
    }
    &:hover {
        background-color: $geyser-50;
        .actions {
            opacity: 1;
        }
    }
    .actions {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        opacity: 0;
        button {
            svg {
                &:hover {
                    color: $primary;
                }
                font-size: 1.8rem;
                padding: 0 !important;
                &:first-of-type {
                    margin-bottom: 3px;
                }
            }
        }
    }
}
