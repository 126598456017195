@import "../../../assets/styles/global.scss";

#app__notifications {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  display: none;
  width: 100vw;
  z-index: 30;
  box-shadow: $dp-5;
  @media #{$md-mobile-screen} {
    width: 564px;
  }
  &.app__notifications__show {
    animation: slide-left .3s ease-out;
    right: 0 !important;
    display: flex;
  }
  @keyframes slide-left {
    from {
      transform: translateX(1000px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes dislay-content {
    from {
      transform: translateY(16px);
    }
    to {
      transform: translateY(0);
    }
  }

  .app__notifications__menu {
    width: 84px;
    display: grid;
    grid-template-rows: 50px 1fr 50px;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    border-right: solid 1px $stroke-color;
    padding: 8px 4px;
    background-color: $white;
    .close__btn svg {
      font-size: 1.3rem;
    }
    .applications__list {
      &__item {
        display: flex;
        padding: 6px 4px;
        border-radius: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 8px;
        transition: all .1s ease-in-out;
        &:hover {
          transform: scale(1.02);
          background-color: $geyser-50;
        }
        >span {
          margin-top: 8px;
          font-size: $font-sm;
          color: $geyser-700;
          text-transform: capitalize;
        }
        >svg {
          font-size: 1.8rem;
          color: $geyser-700;
        }
        &:not(:last-of-type) {
          margin-bottom: 32px;
        }
        &__selected {
          background-color: $geyser-50;
          >span {
            color: $primary;
          }
          >svg {
            color: $primary;
          }
        }
      }
    }
    .manage__notifications__btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      >svg {
        font-size: 2.2rem;
        color: $geyser-700;
      }
      >span {
        font-size: $font-sm;
        color: $geyser-700;
      }
    }
  }

  .app__notifications__content {
    width: 100%;
    background-color: $bg-dark;
    &__list {
      display: flex;
      flex-direction: column;
    }
    &__header {
      display: flex;
      border-bottom: solid 1px $stroke-color;
      justify-content: space-between;
      align-items: center;
      padding: 4px 20px;
      height: 56px;
    }
    &__infos {
      height: calc(100vh - 60px);
      animation: dislay-content .15s ease-in-out;
      overflow-y: auto;
      overflow-x: hidden;
      .all__notifs__message {
        display: flex;
        padding: 64px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
          color: $primary;
          font-size: 3rem;
          margin-bottom: 12px;
        }
        p {
          font-size: $font-lg;
          color: $geyser-900;
          text-align: center;
        }
      }
      .skeleton__loader__block {
        display: flex;
        flex-direction: column;
        margin: 12px 16px;
        >svg {
          margin-bottom: 6px;
          &:last-of-type {
            width: 25%;
          }
        }
      }
    }
  }
}
