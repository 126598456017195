@import "../../../../../../assets/styles/global.scss";

.setup__business {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    > div {
        margin-bottom: 2.2rem;
        display: flex;
        align-items: center;
        > input {
            transform: scale(1.5);
            cursor: pointer;
            color: $primary;
        }
        //gap
        > * + * {
            margin-left: 2.2rem;
        }

        > div {
            > p {
                font-size: $font-lg;
            }
        }
    }
}
