@import "../../../../../../assets/styles/global.scss";

.setup__location {
    .locations {
        display: flex;
        flex-direction: column;
        width: 100%;

        .locations__group {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            > ul {
                > li {
                    margin-top: 16px;
                    display: flex;
                    font-size: $font-lg;
                    color: $geyser-700;
                    > * + * {
                        margin-left: 40px;
                    }
                }
            }
        }
    }

    .loc__time {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 180px;
        row-gap: 36px;

        > div {
            display: flex;
            flex-direction: column;

            > .loc__weekday {
                margin-bottom: 36px;
                > .check__wrapper {
                    display: flex;
                    align-items: center;
                    > span {
                        font-size: $font-lg;
                        color: $geyser-900;
                        margin-right: 36px;
                        width: 80px;
                    }
                }
                > .time {
                    display: flex;
                    > *:last-child {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .form__container {
        display: grid;
        row-gap: 15px;
        > .form__wrapper {
            position: relative;
            display: grid;
            > label {
                margin-bottom: 5px;
                font-size: $font-sm;
                color: $grey-300;
            }
        }
    }
}
