@import "../../../../../assets/styles/global.scss";

.input__places {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .org-location__search-description {
        display: flex;
        align-items: center;
        > p {
            font-size: $font-lg;
            color: $geyser-500;
            margin-bottom: 8px;
            text-align: left;
        }

        > svg {
            align-self: flex-start;
            width: 20px;
            height: auto;
            color: $geyser-500;
        }
    }

    .search__results {
        @extend %dropdownHidden;
        max-height: 170px;
        width: 100%;
        padding: 10px 0;
        overflow-y: auto;
        border: 1px solid $stroke-color;
        box-shadow: $dp-3;
        border-radius: 4px;
        background-color: $white;
        &.show {
            @extend %dropdownShow;
        }

        .message {
            display: flex;
            font-size: $font-lg;
            color: $grey-300;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
        }

        > .place {
            cursor: pointer;
            padding: 5px 20px;
            transition: all 0.2s ease-in-out;
            color: $grey-300;
            font-size: $font-md;
            line-height: 18px;
            animation: renderLocation 0.2s ease-in-out;
            &:hover {
                color: $grey-500;
            }
            @keyframes renderLocation {
                from {
                    transform: translateY(10px);
                    opacity: 0;
                }
                to {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
            &.selected {
                font-weight: 600;
                font-size: 1.6rem;
                &:hover {
                    background-color: $white;
                }
            }
        }
        .loader-places__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            height: 100%;
        }
        input {
            width: 100%;
        }
        .button__wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 6px 18px;
            border-top: solid 1px $stroke-color;
            margin-top: 8px;
            position: sticky;
            z-index: 5;
            button {
                margin-left: 6px;
            }
        }
    }
}
