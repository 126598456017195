@import "../../../../assets/styles/global";

body,
#root {
    background-color: $grey-100;
}
.auth-container {
    height: 100vh;
    background-color: $grey-100;

    > .row {
        height: 100%;
        > .column {
            display: grid;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-right: none;

            @media #{$sm-desktop-screen} {
                width: 50%;
                &:first-child {
                    border-right: solid 2px $stroke-color;
                }
            }
        }
        > .img-container {
            align-self: flex-end;
            position: relative;
            height: 100%;
            justify-items: center;
            display: none;
            background-color: $white;
            @media #{$sm-desktop-screen} {
                display: flex;
            }
            > svg {
                width: 70%;
                height: auto;
                @media #{$lg-mobile-screen-only} {
                    width: 60%;
                }
            }
            .auth-img {
                position: absolute;
                left: 0;
            }
        }
    }

    .auth-container__card {
        display: grid;
        justify-items: center;

        .auth-container__title {
            color: $primary;
            font-size: 2.3rem;
            font-weight: 400;
            text-align: center;
            padding: 35px 0;
        }

        .auth-container__desc, .auth-container__description  {
            color: $geyser-500;
            font-size: 1.2rem;
            text-align: center;
            transform: translateY(-40px);
            >span {
                color: $primary;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .auth-container__description {
            transform: translateY(0);
            margin-top: 25px;
        }

        .auth-container__error-container {
            background-color: $light-danger;
            border-radius: 10px;
            .auth-container__error-message {
                font-size: 1.4rem;
                color: $danger;
                padding: 5px 20px;
                text-align: center;
                font-family: "Poppins Regular";
            }
        }

        .auth-container__input-container {
            display: grid;
            position: relative;
            > svg {
                font-size: 17px;
                display: block;
                margin: 22px 20px;
                position: absolute;
                color: $geyser-500;
            }

            .auth-container__input {
                margin: 10px 0;
                width: 35rem;

                &::placeholder {
                    text-transform: capitalize;
                }

                @media screen and (max-width: 300px) {
                    min-width: 20rem;
                }
            }

            .form-invalid {
                border-bottom-color: $valencia-500 !important;
            }

            .react-tel-input {
                input {
                    width: 260px !important;
                }
            }

            > svg {
                cursor: pointer;
                right: 0;
            }
        }

        .error__text {
            font-size: 1.1rem;
            color: $valencia-500;
            margin-top: -5px;
        }

        .info__text {
            font-size: 1.1rem;
            color: $geyser-500;
            margin-top: -5px;
            text-align: center;
            line-height: 18px !important;
            span {
                line-height: 18px !important;
            }
        }

        .auth-container__input-container-code {
            display: grid;
            grid-template-columns: repeat(6, auto);
            position: relative;
            justify-content: center;

            .auth-container__input-code {
                position: absolute;
                border: none;
                font-size: 16px;
                font-family: "Poppins Regular", sans-serif;
                text-align: center;
                background-color: transparent;
                outline: none;
                height: 15px;
                margin: 0 10px;
                color: $grey-500;
                text-align: center;
                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type="number"] {
                    -moz-appearance: textfield;
                }
            }

            .auth-container__code-box {
                width: 30px;
                height: 20px;
                margin: 0 10px;
                font-size: 16px;
                font-family: "Poppins Regular", sans-serif;
                text-align: center;
                color: $grey-500;
                border: 2px $geyser-500;
                border-style: none none solid none;
                cursor: text;
                &:nth-child(4) {
                    // margin-right: 60px;
                }
            }
        }

        .auth-container__link--forgot {
            padding: 10px 10px;
            font-size: 12px;
            display: block;
            color: $geyser-500;
            &:hover {
                text-decoration: underline;
            }
        }
        .auth-container__button-primary {
            margin: 30px 10px 10px;
        }

        .auth-container__button-container {
            display: grid;
            justify-content: center;
            grid-gap: 20px;
            margin-bottom: 30PX;
            margin-top: 5px;
            .auth-container__link--create-account {
                font-size: 1.2rem;
                color: $geyser-500;
                text-align: center;
                padding-top: 10px;
                >a {
                    color: $primary;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.org-creation__loader--wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > svg {
        width: 300px;
        height: auto;
        @media #{$sm-desktop-screen} {
            width: 550px;
        }
    }
    > p {
        @include textPadEffect;
        font-size: $font-2xl;
        line-height: $lh-32;
        color: $primary;
        margin-top: 20px;
    }
}
